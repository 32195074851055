import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container } from 'react-bootstrap'

import Hero from '../components/Hero/Hero'
import HowItWork from '../components/HowItWork/HowItWork'
import Layout from '../components/Layout/Layout'
import { default as Rank } from '../components/Ranking/Ranking'
import Seo from '../components/Seo/Seo'
import config from '../../config.json'

export default function LifeRanking() {
  const data = useStaticQuery(query)
  const ranking = data.allRanking.edges
  const seo = data.wpPage.seo
  const texts = {
    subheading: 'Ranking',
    heading: 'Ubezpieczeń Zdrowotnych',
    paragraphs: [
      'Chcesz chronić zdrowie swoje i rodziny? Skorzystaj z aktualnego Rankingu Ubezpieczeń Zdrowotnych. Czytaj opinie klientów, poznawaj produkty i wybieraj świadomie! Z naszym rankingiem to bardzo proste. Nie wiesz, na jakie ubezpieczenie się zdecydować?',
    ],
  }

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl} />
      <Hero
        subheading={texts.subheading}
        heading={texts.heading}
        paragraphs={texts.paragraphs}
        button={{
          text: 'Porównaj ubezpieczenia zdrowotne',
          internalLink: config.CALC_LINK_HEALTH,
        }}
        img={
          <StaticImage
            src="../assets/images/home/home-bg.png"
            loading="eager"
            width={650}
            quality={90}
            placeholder="none"
            alt="Ranking dobrych polis na życie"
            title="Ranking dobrych polis na życie"
          />
        }
      />
      <Container>
        <Rank ranking={ranking} allowSorting={true} />
      </Container>
      <HowItWork className="mb-5" rankingType="ubezpieczenia-zdrowotne" />
    </Layout>
  )
}

const query = graphql`
  {
    wpPage(slug: { eq: "ranking-ubezpieczen-zdrowotnych" }) {
      seo {
        metaDesc
        title
        focuskw
        opengraphUrl
      }
    }
    allRanking(sort: { fields: average, order: DESC }, filter: { rankingType: { eq: "ubezpieczenia-zdrowotne" } }) {
      edges {
        node {
          average
          company
          companyId
          id
          opinionsCount
          position
          sum
          slug
          rankingType
          formUrl
        }
      }
    }
  }
`
